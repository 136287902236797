import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import { getId, getThumb } from "../components/getId";
import { renderRichText } from "gatsby-source-contentful/rich-text";

function lineBreak(text) {
  var newtext = text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]);
  return newtext;
}

class ArtistPage extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const ModalHiddenEventListener = (el, fn, owner) => {
      const opts = {
          attributeFilter: ["style"]
        },
        mo = new MutationObserver(mutations => {
          for (let mutation of mutations) {
            if (
              mutation.type === "attributes" &&
              mutation.attributeName === "style" &&
              mutation.target.getAttribute("style") === "display: none;"
            ) {
              mo.disconnect();
              fn({
                owner: owner,
                element: mutation.target
              });
            }
          }
        });
      mo.observe(el, opts);
    };

    const modals = document.getElementsByClassName("modal");

    const onHide = e => {
      for (var i = 0; i < modals.length; i++) {
        var el = document.getElementById("modal" + i);
        var temp = el.innerHTML;
        el.innerHTML = "";
        el.innerHTML = temp;
        ModalHiddenEventListener(el, onHide, this);
      }
    };

    for (var i = 0; i < modals.length; i++) {
      var el = document.getElementById("modal" + i);
      ModalHiddenEventListener(el, onHide, this);
    }
  }

  render() {
    return (
      <div>
        <Seo title="Commercials" />
        <div class="row row-cols-1 row-cols-md-3 ">
          {this.props.data.allContentfulCommercials.nodes.map((artist, int) => (
            <div class="col">
              <div
                class="mb-4 ratio ratio-16x9"
                style={{
                  background:
                    "url(" + getThumb(artist.video) + ") no-repeat center"
                }}
              >
                <button
                  type="button"
                  class="btn"
                  data-bs-toggle="modal"
                  data-bs-target={"#modal" + int}
                />
              </div>
              <h3 class="text-center">{artist.title}</h3>
              <div class="videodesc text-center">
                {lineBreak(artist.description.description)}
              </div>

              <div
                class="modal fade"
                id={"modal" + int}
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content">
                    <div class="modal-body ratio ratio-16x9">
                      <iframe
                        width="420"
                        height="315"
                        src={getId(artist.video)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export const query = graphql`
  {
    allContentfulCommercials(sort: { fields: [order], order: DESC }) {
      nodes {
        title
        video
        order
        description {
          description
        }
      }
    }
  }
`;

export default ArtistPage;
